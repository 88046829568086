<template>
  <BaseButton
    :class="[classes.root, rootModifiers]"
    type="nuxt-link"
    :url="url"
    @click="clickOnBanner"
  >
    <div :class="[classes.wrapper, wrapperModifiers]">
      <div :class="[classes.inner, innerModifiers]">
        <div :class="[classes.body, bodyModifiers]">
          <Icon
            name="common:percent"
            :size="props.isOverCatalog ? 32 : 24"
            :class="percentModifiers"
          />
          <div :class="classes.textWrapper">
            <div :class="[classes.title, titleModifiers]">
              Сейчас выгодно!
            </div>
            <div :class="[classes.text, textModifiers]">
              Купи {{ middleText }} <br v-if="props.isOverCatalog && abStore.isPM640VariantA" :class="classes.breakLine"> с самой большой скидкой
            </div>
          </div>
        </div>

        <BaseButton
          intent="base-primary"
          size="middle"
          :class="[classes.button, buttonMofifiers]"
        >
          Купить iPhone
        </BaseButton>
      </div>
    </div>
  </BaseButton>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { computed } from 'vue'

interface Props {
  isSmallWidth?: boolean
  isOverCatalog?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSmallWidth: false,
  isOverCatalog: false,
})

const classes = {
  root: cx([
    'bg-gradient-banner',
    'cursor-pointer',
    'transition',
    'duration-300',
    'hover:opacity-90',
    'relative',
    'z-40',
    'w-full',
    'xl:block',
  ]),
  wrapper: cx([
    `bg-[url('/static/images/banner/desktop-percents.png')]`,
    'bg-contain',
    'bg-no-repeat',
    'bg-center',
    'h-full',
  ]),
  inner: cx([
    'container',
    'backdrop-blur-[2px]',
    'flex',
    'gap-[64px]',
    'py-[5.5px]',
    'justify-center',
    'rounded-[8px]',
  ]),
  body: cx([
    'flex',
    'gap-[12px]',
    'items-center',
  ]),
  button: cx([
    'xl:w-fit',
    'py-[6px]',
    'px-[24px]',
    '!text-[14px]/[17px]',
    '!rounded-[8px]',
    '!text-[#000]',
    '!font-bold',
  ]),
  textWrapper: 'font-bold',
  title: cx([
    'uppercase',
    'text-icon-yellow',
    'text-[10px]/[11px]',
  ]),
  text: cx([
    'text-[14px]/[14px]',
    'text-[#E0ECF8]',
  ]),
  breakLine: cx([
    'hidden',
    'xl:block',
  ]),
}

const rootModifiers = computed(() => {
  let rootClasses = ''
  if (!props.isOverCatalog) {
    rootClasses += 'hidden'
  }
  if (props.isSmallWidth) {
    rootClasses += ' container rounded-[8px]'
  }
  return rootClasses
})
const wrapperModifiers = computed(() => {
  if (props.isOverCatalog) {
    return `xl:bg-[url('/static/images/banner/desktop-percents-2.png')] xl:bg-[length:100%_100px] 
            bg-[url('/static/images/banner/mobile-percents.png')] bg-left-top bg-[length:290px_55px]`
  }
  return ''
})
const innerModifiers = computed(() => {
  let innerClasses = ''
  if (props.isSmallWidth) {
    innerClasses += 'rounded-[8px]'
  }
  if (props.isOverCatalog) {
    innerClasses += 'h-full xl:items-center items-start justify-between flex-col xl:flex-row !gap-[16px] !p-[24px] !pt-[30px]'
  }
  return innerClasses
})
const bodyModifiers = computed(() => {
  return props.isOverCatalog ? 'xl:items-center items-start xl:max-h-[52px] xl:flex-row flex-col' : ''
})
const textModifiers = computed(() => {
  return props.isOverCatalog ? 'text-[20px]/[20px] xl-mid:text-[23px]/[21px] mt-[4px]' : ''
})
const buttonMofifiers = computed(() => {
  return props.isOverCatalog ? 'w-full ' : ''
})
const percentModifiers = computed(() => {
  return props.isOverCatalog ? 'mt-[4px] self-start xl:self-center' : ''
})
const titleModifiers = computed(() => {
  return props.isOverCatalog ? 'xl:text-[10px]/[12px] text-[14px]/[16px]' : ''
})

const abStore = useAbStore()

const middleText = computed(() => {
  return abStore.isPM640VariantA
    ? 'iPhone прошлых поколений'
    : !abStore.isPM640VariantA && props.isOverCatalog
        ? 'iPhone 11'
        : 'старую модель iPhone'
})

const url = computed(() => abStore.isPM640VariantA ? '/phone-i?c_sale_buyout=1' : '/phone-i/model-i11?c_sale_buyout=1')

const catalogStore = useCatalogStore()

const clickOnBanner = () => {
  catalogStore.resetFilters()
  emitYandexMetrika(YANDEX_METRIKA_GOALS.SALE_BANNER_CKICKED)
  emitMixpanelEventSaleBannerClicked()
}
</script>